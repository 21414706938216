import {
  FETCH_PROGRAM_SESSIONS,
  FETCH_PROGRAM_SESSION_BY_ID,
  FETCH_EXPANDED_SESSION_INFO,
} from '../../actions/sessions';
import { FETCH_WORKOUT_SESSION_BY_ID, FETCH_WORKOUT_SESSION } from '../../actions/workout';

const initialState = {
  all: null,
  sessionId: null,
  sessionInfo: {
    latestSessionCompleted: false,
    latestSessionId: null,
    nextSessionId: null,
    nextTrackSessionId: null,
    completedSessionsCount: null,
    sessionsCount: null,
    relatedProductId: null,
    phasesInfo: [],
  },
  isFetchSessionInfoLoading: false,
};

export default function Index(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROGRAM_SESSION_BY_ID.success: {
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        sessionId: action.payload.id,
      };
    }
    case FETCH_WORKOUT_SESSION_BY_ID.request: {
      return {
        ...state,
        sessionId: parseInt(action.payload.workoutId, 10),
      };
    }
    case FETCH_PROGRAM_SESSIONS.success: {
      return {
        state,
      };
    }
    case FETCH_WORKOUT_SESSION.success: {
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.session.id]: {
            ...action.payload.session,
          },
        },
        sessionId: action.payload.session.id,
      };
    }
    case FETCH_EXPANDED_SESSION_INFO.request: {
      return {
        ...state,
        sessionInfo: initialState.sessionInfo,
        isFetchSessionInfoLoading: true,
      };
    }
    case FETCH_EXPANDED_SESSION_INFO.success: {
      return {
        ...state,
        sessionInfo: { ...action.payload.sessionInfo },
        isFetchSessionInfoLoading: false,
      };
    }
    case FETCH_EXPANDED_SESSION_INFO.failure: {
      return {
        ...state,
        isFetchSessionInfoLoading: false,
      };
    }

    default:
      return state;
  }
}

// get user program session
export const getProgramSession = state => {
  const { sessionId = null, all: items = null } = state.sessions || {};

  if (!items) return null;

  return items[sessionId] || null;
};

// get session duration options
export const getSessionDurationOptions = state => {
  const { sessionId = null, all = {} } = state.sessions || {};
  const session = sessionId && all ? all[sessionId] : null;

  return session ? session.durationOptions : null;
};

export const getSessionPhasesInfo = state => {
  const { all = [], sessionId = null } = state.sessions || {};

  const session = sessionId && all ? all[sessionId] : null;

  return session ? session.phasesInfo : null;
};
